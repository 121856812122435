import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Error, Auth0ErrorSource } from '../../../interfaces/Auth0Error';
import { AuthService } from '@auth0/auth0-angular';
import { Auth0Settings } from 'src/app/interfaces/AppSettings';
import { AppSettingsService } from 'src/app/data-services/app-settings.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  error: Auth0Error;
  navigation: string;
  settings: Auth0Settings;

  constructor(private router: Router,   private auth0Service: AuthService) {
    this.error = this.router.getCurrentNavigation()?.extras?.state as Auth0Error;
    if (this.error?.errorId) {
      this.setAction(this.error);
    }
    else {
      this.navigation = '/#/login'
    }
    this.settings = AppSettingsService.Settings.auth0Settings;
  }

  setAction(err: Auth0Error) {
    switch (err.errorId) {
      case Auth0ErrorSource.UnifiedIdentity:
      case Auth0ErrorSource.Unauthorized:
      default:
        this.navigation = '/#/login'
        break;
    }
  }

  gotoAction() {
    window.location.replace(this.navigation);
  }

  auth0Logout() {
    let returnToUrl = this.settings.appUri + `/auth0/logged-out`;
    const orgId = localStorage.getItem('auth0OrgId');
    if (orgId) {
      returnToUrl = `${returnToUrl}?logout=true&organization=${orgId}`
    
    this.auth0Service.logout({
      logoutParams: {
        returnTo: returnToUrl
      }});
    }
  }

  gotoLoginSetting(): void{
    if(localStorage.getItem('auth0_hasLoggedIn')){
      window.location.replace(this.settings.universalLoginUrl + `/login-preference-selection?force=reset`);
    }
  }
}

