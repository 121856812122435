export class Auth0Error {
    title: string;
    content: string;
    errorId: Auth0ErrorSource;
    hasLockedOut: boolean;
}

export enum Auth0ErrorSource{
    Unauthorized,
    UnifiedIdentity
}
