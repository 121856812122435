import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettingsService } from '../../../data-services/app-settings.service';
import { MsgsTypes, UserMessageComponent } from '../../shared-components/user-message/user-message.component';

@Component({
  selector: 'app-spam-unsubscribe',
  templateUrl: './spam-unsubscribe.component.html',
  styleUrls: ['./spam-unsubscribe.component.scss']
})
export class SpamUnsubscribeComponent implements OnInit {

  @ViewChild(UserMessageComponent, { static: true }) userMessage: UserMessageComponent;

  private baseUrl = AppSettingsService.Settings.baseUrl;
  private id: string;

  public practiceName: string;
  ready = false;
  errorMsg = false;
  successfulMsg: string = 'Your request was successfully processed.';
  processingRequestMsg = 'We are processing your request please wait, this may take a few seconds.'
  isDisabled: boolean = false;

  constructor(private http: HttpClient,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe((queryParams) => {
        this.id = queryParams['id'];
        this.practiceName = queryParams['practiceName'];

        if (!this.practiceName) {
          this.practiceName = 'this practice';
        }

        if (!this.id) {
          this.userMessage.clear()
          this.errorMsg = true;
        }
        else{
          this.unsubscribeEmail();
        }
      });
  }
  unsubscribeEmail() {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.userMessage.createMsg(this.processingRequestMsg, MsgsTypes.info, false);

    // api/v1/CanSpam/Unsubscribe/
    const api = '/CanSpam/Unsubscribe/';
    this.http.post(`${this.baseUrl}${api}`, '"' + this.id + '"', httpOptions)
      .subscribe((result) => {
        console.log(result);
        this.isDisabled = true;
        this.ready = true;
        this.userMessage.Message = this.successfulMsg;
      }, (error) => {
        console.log(error);
        this.userMessage.clear()
        this.errorMsg = true;
      });
  }

}
